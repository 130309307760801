<script setup lang="ts">
import { useLeaseNavigation } from '@register/components/Review/composables'
import {
  createGroupURIFactory,
  useApiLeaseCardSidebar,
  usePrefetchLeaseCardSections,
} from '@manager'

const divisionId = getCurrentDivisionId()
const leaseId = getCurrentLeaseId()

const { data: sidebar } = useApiLeaseCardSidebar(divisionId, leaseId)
const prefetchGroupFields = usePrefetchLeaseCardSections(divisionId)

const { currentPage, nextPage, previousPage, exclusiveOpenGroup } =
  useLeaseNavigation(
    computed(() => sidebar.value ?? []),
    {
      routeId: ({ params }) => params.id as string,
    },
  )

watch(
  currentPage,
  () => {
    if (nextPage.value) {
      prefetchGroupFields(Number(nextPage.value.id))
    }
    if (previousPage.value) {
      prefetchGroupFields(Number(previousPage.value.id))
    }
  },
  { immediate: true },
)

const createGroupURI = createGroupURIFactory(divisionId, leaseId)
</script>

<template>
  <section class="grid h-8 grid-cols-2">
    <div>
      <Button
        v-if="previousPage"
        color="secondary"
        class="group h-8 px-4 py-2 text-xs"
        :to="createGroupURI(previousPage)"
        @click="exclusiveOpenGroup(previousPage)"
      >
        <Icon name="chevron-left" filled />
        <span class="hidden group-hover:flex">
          {{ previousPage.title }}
        </span>
      </Button>
    </div>
    <div class="flex justify-end">
      <Button
        v-if="nextPage"
        color="secondary"
        class="group h-8 px-4 py-2 text-xs"
        :to="createGroupURI(nextPage)"
        @click="exclusiveOpenGroup(nextPage)"
      >
        <span class="hidden group-hover:flex">
          {{ nextPage.title }}
        </span>
        <Icon name="chevron-right" filled />
      </Button>
    </div>
  </section>
</template>
